.App {
  /*text-align: center;*/
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #faf7f0;
  /*background-image: url('background.png');*/
}

* {
  /*font-size: 12px;*/
  font-family: Tahoma, sans-serif;
 }

 html { 
  zoom: .8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #7bb6e2;
  box-shadow: 2px 2px 4px 4px lightgrey;
  height:44px;
  gap: 0px;
  z-index: 99;
  position: relative;
}

.SideMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120vh;
  min-width: 64px;
  background-color: #f1d996;
  box-shadow: 2px 2px 4px 4px lightgrey;
  gap: 30px;
  padding-top: 50px;
}

.logo {
  width: 198px;
  height: 21px;
  cursor: pointer;
  padding-left: 8px;
  /*background-color: white;*/
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-cell-value-wrap-red {
  border: 1px solid #ff7875;
  padding: 5px 12px;
  cursor: pointer;
}

.cell-value-wrap {
  padding: 5px 12px;
}

.color-row {
  background-color: #dce7ec;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.ant-table-tbody > tr > td {
  border: 2px solid #f7fafc;
  border-radius: 4px;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  /*line-height:1em;*/
}

.deal-table table {
  width: auto;
  min-width: unset !important;
}

.withprewrap {
  white-space: pre-wrap;
}

.ant-table-thead .ant-table-cell {
  padding: 2px 6px 2px 6px !important;
}
.ant-table-tbody {
  padding: 2px 4px 2px 4px !important;
}
.ant-table-tbody > tr > td {
  padding: 2px 4px 2px 4px !important;
}

.messages-table .ant-table-row {
  max-height: 20px !important;
  line-height: 1;
}

.messages-table .ant-table-tbody{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.messages-table .ant-table-tbody > tr > td{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/*.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
}
.ant-select .ant-select-selector {
  border-radius:0px;
}*/
.ant-select-selection-item {
  font-size: 13px;
}
.ant-select-item-option-content {
  font-size: 14px;
}
.container {
  /*overflow-x: auto;*/
  display: flex;
  flex-direction: row;
}
/*.table-striped-rows tr:nth-child(2n) td {
  background-color: #f5f5f5;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}*/
.ant-table-thead .ant-table-cell {
  background-color: #7bb6e2 !important;
}

.ant-collapse-header {
  font-weight: 900;
  font-size: 15px;
}

.message-panel-menu {
  width: 366px;
  display: flex;
  flex-direction: row;
}
.message-panel {
  display: inline-block;
  padding: 6px;
  /*border: 1px solid #d9d9d9;*/
  width: 360px;
  height: 400px;
  /*max-height: 360px;*/
  overflow-y: auto;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.message-panel::-webkit-scrollbar {
  width: 18px;
}
.message-panel::-webkit-scrollbar-track {
  border-radius: 4px;
}
.message-panel::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3px;
}
.message-panel-no-scroll {
  display: inline-block;
  padding: 6px;
  /*border: 1px solid #d9d9d9;*/
  width: 360px;
  /*max-height: 360px;*/
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.wa-message-panel {
  display: inline-block;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 10px;
  padding: 10px;
  width: 600px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 2px lightgrey;
}
.wa-message-panel2 {
  display: inline-block;
  text-align: justify;
  text-justify: inter-word;
  margin-left: -14px;
  margin-top: 62px;
  padding-top: 0px;
  padding-left: 15px;
  width: 400px;
  height: 600px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 2px lightgrey;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* At most 3 columns */
  grid-gap: 12px; /* Adjust spacing between items as needed */
}

.grid-container-offers {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* At most 2 columns */
  grid-gap: 12px; /* Adjust spacing between items as needed */
}

.grid-item {
  border: 3px solid #ddd;
  border-radius: 6px;
  padding: 16px;
  text-align: center;
}

.settings-panel {
  margin-left: 10px;
  padding-right: 60px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 2px lightgrey;
}

.other-deal-info {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.message-panel-button {
  margin: 0px 0px 0px 6px;
}
.no-trans {
  animation-duration: 0s !important;
}
.message-id {
  /*padding: 0;*/
  font-size: 12px;
}
.filter-panel {
  margin: 0;
}
.search-bar {
  padding-left: 2px;
  padding-top: 8px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.message-search-bar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  float: right;
}

.text-center {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  width: 420px;
  left: 50%;
  top: 40%;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 4px lightgrey;
  background-color: #faf7f0;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.my-context-menu {
  max-width: 175px;
}
.btn-style {
  background: #dfa814 !important;
  color: white !important;
  font-size: 14px !important;
}
.btn-style:hover {
  background: #F8B501 !important;
  color: white !important;
  border-color: white !important;
}
.btn-style-green {
  background: #73D673 !important;
  color: white !important;
  font-size: 14px !important;
}
.btn-style-green:hover {
  background: #b3e2b3 !important;
  color: white !important;
  border-color: white !important;
}
.btn-style-red {
  background: #ff7875 !important;
  color: white !important;
  font-size: 14px !important;
}
.btn-style-red:hover {
  background: #ffa39e !important;
  color: white !important;
  border-color: white !important;
}
.btn-style-blue {
  background: #6499c2 !important;
  color: white !important;
  font-size: 14px !important;
}
.btn-style-blue:hover {
  background: #7BB6E2 !important;
  color: white !important;
  border-color: white !important;
}

.vendor-style {
  padding: 0;
  font-size:14px;
  max-width:110px;
  white-space:pre-wrap;
  color: #1677FF;
  cursor: pointer;
}

.link-btn-style {
  color: #1677FF;
  cursor: pointer;
  text-decoration: underline;
}

.long-text-without-spaces-small {
  display: inline-block;
  max-width: 150px;
  word-wrap: break-word; /* Allow the text to wrap within the width */
  overflow-wrap: break-word;
  word-break: break-all;
}

.long-text-without-spaces {
  display: inline-block;
  max-width: 200px;
  word-wrap: break-word; /* Allow the text to wrap within the width */
  overflow-wrap: break-word;
  word-break: break-all;
}

.long-text-without-spaces-long {
  display: inline-block;
  max-width: 600px;
  word-wrap: break-word; /* Allow the text to wrap within the width */
  overflow-wrap: break-word;
  word-break: break-all;
}

.long-text-without-spaces-long-msg {
  display: inline-block;
  max-width: 600px;
  word-wrap: break-word; /* Allow the text to wrap within the width */
  overflow-wrap: break-word;
  word-break: break-all;
}

.long-text-without-spaces-long-msg:hover {
  color: #1677FF;
  cursor: pointer;
  text-decoration: underline;
}

.long-text-without-spaces-long-msg2 {
  
}

.long-text-without-spaces-long-msg2:hover {
  color: #1677FF;
  cursor: pointer;
  text-decoration: underline;
}

.nav-link {
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-link-clicked {
  color: red;
}

/* Custom tag styles */
.custom-tag {
  display: inline-block;
  padding: 0 4px; /* Adjust left and right padding */
  margin: 1px;
  font-size: 12px;
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
}

.custom-tag:hover {
  background-color: #e6e6e6;
}

.custom-tag:active {
  background-color: #d9d9d9;
}

hr {
  border: none;
  height: 1px;
  background-color: #d9d9d9;
  margin: 1px 0;
}


.chat-window {
  height: 1000px; /* Set a fixed height or adjust as needed */
  width: 1240px;
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 2px lightgrey;
  border: 1px solid #ccc; /* Add a border for better visibility */
  padding: 10px; /* Add padding to the chat window */
}

.message-list {
  max-width: 1360px; /* Set a maximum width for the messages */
  margin: 0 auto; /* Center the messages within the chat window */
}

.ant-comment-content {
  padding: 8px; /* Add padding to the message content */
  border: 1px solid #e8e8e8; /* Add a border for each message */
  border-radius: 8px; /* Optional: Add rounded corners to the messages */
  background-color: #f0f0f0; /* Set a background color for better contrast */
}

.ant-comment-author {
  font-weight: bold;
}

.ant-comment-avatar {
  margin-right: 8px; /* Add some spacing between the avatar and message content */
}

.chat-time {
  color: black; /* Optional: Set a color for the chat time */
  font-size: 12px; /* Optional: Adjust the font size */
  margin-top: 4px; /* Optional: Add some top margin for spacing */
}